













































































































// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 1.76rem);
	padding: 0.2rem;
	font-family: PingFangSC-Regular;
}


.list-cell-li {
	padding: 0.2rem 0;
	margin: 0 0.2rem;
	border-bottom: 1px solid #EDEDED;
	// line-height: .45rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	&:last-child {
		border-bottom: 0;
	}
	.address {
		font-size: 0.24rem;
		color: #333333;
		width: 3.72rem;
		word-break: break-all;
		text-align: right;
	}
	.typebtn {
		padding: .02rem .12rem;
		background: #EFF7FF;
		border-radius: .04rem;
		margin-left: .1rem;
	}
}
.comTips {
	color: #666666;
	font-size: .28rem;
	line-height: .45rem;
	&.comTipsPadd {
		color: #666666;
		font-size: .28rem;
		line-height: .4rem;
		padding: .25rem;
		padding-bottom: 0.8rem;
	}
	.caption {
		color: #333333;
		font-size: .28rem;
		font-weight: bold;
		line-height: .45rem;
		margin-bottom: .25rem;
	}
	p {
		margin-bottom: .25rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: .88rem;
	line-height: .88rem;
	background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
	border-radius: 0.16rem;
}
.contWhiteBox {
	margin-bottom: 0.2rem;
	border-radius: 0.16rem;
}
.mainFontLightColor {
	font-size: 0.28rem;
}
.mainFontColor {
	color: #333333 !important;
	font-size: 0.24rem;
}
// ----------20200411[end]-----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;